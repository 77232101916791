/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #274760;
$secondary: rgba($primary, 0.52);
$ternary: #b7b7b7;
$border: #eaeaea;
$gray: #fafafa;
$accent: #307bc4;
$accent-start: #004FFF;
$accent-end: #00A4FF;
$gray-dark: #585858;
$accent-three: #002c8a;
$accent-four: #00679e;